import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import file_ingles from 'sections/pdfs/life_paper_ingles.pdf'
import file_espanol from 'sections/pdfs/life_paper_espanol.pdf'
import Particles from 'react-particles-js';
import fondo from 'sections/images/fondos/mesa1.jpg'
import Idioma from 'sections/idiomas/contents.json'
import Cookies from 'universal-cookie';
import logito from 'sections/images/gw-08.png';

let url=null;
const cookies = new Cookies();
let title=""
let a=cookies.get('idioma')

if(a=="ingles"){
    title=Idioma.ingles.life_paper.opcion;
    url = file_ingles;
}else{
    title=Idioma.espanol.life_paper.opcion;
     url = file_espanol;
}


 
  
export default function Test() {

  let a=cookies.get('pagina')


  if(a==undefined){
    cookies.set('pagina', 1, { path: '/' });
  }
  a=parseInt(a)
 
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  /*To Prevent right click on screen*/

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(a);
  }
  
  function changePage(offset) {
    let a=cookies.get('pagina')
    a=parseInt(a)
    a=a+offset;
    
  
  
    setPageNumber(prevPageNumber => prevPageNumber + offset);
    cookies.set('pagina', a, { path: '/' });
  }
  
  function previousPage() {
    changePage(-1);
  }
  
  function nextPage() {
    changePage(1);
  }
let contador=0;
  function handleClick(e){
    e.preventDefault();
    contador++;
     changePage(1);
    alert('You clicked submit.'+contador);
  }
  
  const Section = styled.section`
  background-image: url(${fondo});
  background-size: cover;
  background-repeat: no-repeat;
          background-color: #050505;
          padding: 100px 0;
          .particles {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        `

  const Resumen=styled.div`
  background-color: #050505;
    margin:auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `

  const Submit = styled.button`
        
    height: 50px;
    width: 200px;
    position: relative;
    border: none;
    overflow: hidden;
    transition: .5s;
    &::after {
      position: absolute;
      content:'';
      display: inline-block;
      background: rgba(255, 0, 255, 1);
      background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(195 62 195 / 63%) 25%, rgba(255, 0, 255, 1) 51%, rgba(0, 0, 0, 1) 100%);
      height: 100%;
      width:  140%;
      top: 0;
      left: 0;
      transition: .5s;
    }
    
    &:hover {
      &::after {
        transform: translateX(-20%);
      }
    }
    span {
      font-size: 15px;
      position: relative;
      top: 0;
      -webkit-text-stroke: 0.5px black;
      color: #fff;
      z-index: 10;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
`

const PDFDocumentWrapper = styled.div`
  canvas {
    margin: 0 1em 0 1em;
    width: 95% !important;
    height: auto !important;
  }
`

const Image = styled.img`
           
width: 75%;
object-fit: cover;
@media (min-width:768px) {
    min-height: 300px;
    max-height: 300px;
}
@media (max-width:768px) {
    min-height: 150px;
    max-height: 150px;
}
`
  return (
    <>
    <Section id="paper_life">
    <Particles
                    className="particles"
                    params={{
                        "particles": {
                            "number": {
                              "value": 100,
                              "density": {
                                "enable": true,
                                "value_area": 2000
                              }
                            },
                            "color": {
                              "value": ["#03afaf","#04e5e5"]
                            },
                            "shape": {
                              "type": "circle",
                              "stroke": {
                                "width": 0,
                                "color": "#fff"
                              }
                            },
                            "opacity": {
                              "value": 0.5,
                              "random": false,
                              "anim": {
                                "enable": true,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                              }
                            },
                            "size": {
                              "value": 8.017060304327615,
                              "random": true,
                              "anim": {
                                "enable": true,
                                "speed": 12.181158184520175,
                                "size_min": 0.1,
                                "sync": true
                              }
                            },
                            "line_linked": {
                              "enable": true,
                              "distance": 150,
                              "color": "#555",
                              "opacity": 0.5,
                              "width": 1
                            },
                            "move": {
                              "enable": true,
                              "speed": 1,
                              "direction": "none",
                              "random": false,
                              "straight": false,
                              "out_mode": "bounce",
                              "bounce": false,
                              "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                              }
                            }
                          },
                          "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                              "onhover": {
                                "enable": false,
                                "mode": "repulse"
                              },
                              "onclick": {
                                "enable": false,
                                "mode": "push"
                              },
                              "resize": true
                            },
                            "modes": {
                              "grab": {
                                "distance": 400,
                                "line_linked": {
                                  "opacity": 1
                                }
                              },
                              "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                              },
                              "repulse": {
                                "distance": 200,
                                "duration": 0.4
                              },
                              "push": {
                                "particles_nb": 4
                              },
                              "remove": {
                                "particles_nb": 2
                              }
                            }
                          },
                          "retina_detect": true
                }}/>
       <div className='container'>
                <div className='row justify-content-center'>
                    <h1 className='text-cyan borde-negro color_fondo_unico py-4 my-4 px-4 mx-4'>Descarga nuestra aplicacion</h1>
                </div>
                <div className='row justify-content-center align-items-center py-5'>
                    <div className='col-md-6  col-12'>
                        <h2 className='text-pink borde-negro text-center'>¿Quieres formar parte de nuestro metaverso? </h2>
                        <br></br>
                        <p className='text-center'>Te invitamos a que descarges nuestra aplicacion donde
                            subiremos actualizaciones de arte,diseño,nft y blockchain
                        </p>
                        <Image src={logito} class="rounded mx-auto d-block py-4 my-4" alt="..."/>
                    </div>
                    <div className='col-md-6  col-12 text-center'>
                            <div className='row'>
                                <div className='col-md-12 col-12 py-5'>
                                <Submit ><span>
                    <a type="button" class=" text-light borde-negro" href="https://cryptoversox.com/apks/cryptoversox.apk" download>DESCARGA ANDROID</a>
                                            </span></Submit>
                                </div>
                                <div className='col-md-12 col-12 py-5'>
                                <Submit ><span>
                    <a type="button" class=" text-light borde-negro" href="https://cryptoversox.com/apks/cryptoversox.apk" download>DESCARGAR IOS</a>
                                            </span></Submit>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
            </Section>

    </>
  );
}